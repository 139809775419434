<template>
  <div :class="$style.settings">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-position="right"
      label-width="120px"
    >
      <div :class="$style.blockTitle">Текст</div>
      <TextEditor :value.sync="form.contentSection.text" />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import TextEditor from '@/components/atoms/TextEditor.vue'
export default {
  components: {
    TextEditor,
  },
  data() {
    return {
      form: {
        contentSection: {
          text: '',
        },
      },

      rules: {
        contentSection: {
          text: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        },
      },
    }
  },
  created() {
    this.getPage('confidential-policy')
  },
  methods: {
    async getPage(slug) {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(slug, 'sellers')
      loading.close()
      if (error) return
      this.form = value.content
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const result = await delivery.ContentServiceCore.PagesActions.update(
            'confidential-policy',
            'sellers',
            this.form,
          )
          if (result.error) {
            alert('Ошибка обновлена страницы: ' + result.error)
          } else {
            alert('Старница успешно обновлена')
          }
          loading.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.settings {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .blockTitle {
    @include blockTitle;
  }
}
</style>
